
:root {
  --greenPrimary: '#028A0F';
  --greenSecondary: '#003E06';
  --greenTertiary: '#00B912';
  --black: '#000000';
  --grey: '#353535';
  --white: '#FFFFFF';
}


.App {
  text-align: center;
  background-color: var(--greenSecondary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--white);
}

.Cog {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Cog {
    animation: cog-spin infinite 20s linear;
  }
}

.App-link {
  color: var(--greenTertiary);
}

@keyframes cog-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
